<template>
  <div class="section">
    <p class="title has-text-centered">
      Users Overview
    </p>
    <p class="has-text-centered is-size-7 has-text-dark has-margin-bottom-20">
      datas are refreshed daily around 6am GMT
    </p>
    <div class="columns is-centered">
      <div class="column is-half has-text-centered">
        <b-button
          v-if="canAddSearch"
          type="is-primary"
          icon-left="account-plus"
          outlined
          tag="router-link"
          to="/new/user"
          rounded
        >
          Add a User
        </b-button>
        <b-button v-else type="is-primary" icon-left="account-plus" outlined disabled rounded>
          Add a User
        </b-button>
      </div>
    </div>

    <UserTable v-if="orderedUser.length > 0" />

    <div v-if="orderedUser.length === 0">
      <div v-if="canAddSearch" class="columns is-centered">
        <div class="column is-half has-text-centered">
          <img class="svg-custom" src="../assets/empty.svg" />
        </div>
      </div>
      <div v-if="user.plan === 'free'" class="section">
        <div class="columns has-text-centered">
          <div class="column">
            <p class="has-text-centered is-size-4 has-text-black container">
              Start your free trial to track users.
            </p>
          </div>
        </div>
        <StripeBilling />
      </div>
    </div>
    <div class="column has-text-centered">
      <b-button
        v-if="canAddSearch"
        size="is-large is-primary"
        tag="router-link"
        to="/new/user"
        outlined
        rounded
        icon-left="account-plus"
      >
        Add a user
      </b-button>
      <div v-else>
        <b-button size="is-large is-primary" disabled outlined rounded icon-left="account-plus">
          Add a user
        </b-button>
        <div class="has-text-danger has-text-weight-light is-size-7">
          <router-link to="setting">
            Upgrade
          </router-link>
          your account to start tracking users
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserTable from "@/components/user/UserTable";
import StripeBilling from "@/components/stripe/StripeBilling";

export default {
  components: { UserTable, StripeBilling },

  computed: {
    orderedUser() {
      const arr = this.tiktok.slice();
      const filtered = arr.filter((value, index, arr) => value.type === "user");
      filtered.sort((a, b) =>
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        a.nick_name.localeCompare(b.nick_name)
      );
      return filtered;
    },
    canAddSearch() {
      if (this.tiktok.length < this.user.limit && this.user.plan !== "free") {
        return true;
      }
      return false;
    },
    ...mapGetters("tiktok", ["tiktok"]),
    ...mapGetters("user", ["user"]),
  },
  mounted() {
    this.$amplitude.logEvent("Users dashboard");
    this.$store.dispatch("tiktok/getAlerts");
  },
};
</script>

<style>
.svg-custom {
  max-height: 300px;
}
</style>
