<template>
  <section class="">
    <div class="columns is-centered">
      <div class="column is-half has-text-centered">
        <b-button rounded outlined type="is-primary" icon-left="download" @click="exportToCSV()">
          Export to CSV
        </b-button>
      </div>
    </div>

    <div class="box">
      <div class="columns is-centered">
        <div class="column is-half">
          <b-field label="Filter by tags">
            <b-taginput
              v-model="tags"
              :data="filteredTags.length === 0 ? allTags : filteredTags"
              autocomplete
              :allow-new="false"
              icon="label"
              placeholder="Search a tag"
              @typing="getFilteredTags"
            />
          </b-field>
        </div>
      </div>
      <div class="box">
        <div class="container">
          <p class="has-text-centered is-size-5">
            Field to display
          </p>
          <div class="container">
            <b-field grouped group-multiline>
              <div class="control">
                <b-checkbox v-model="showFans">
                  Total Fans
                </b-checkbox>
              </div>
              <div class="control">
                <b-checkbox v-model="showLastFans">
                  New Fans
                </b-checkbox>
              </div>
              <div class="control">
                <b-checkbox v-model="showHearts">
                  Total Hearts
                </b-checkbox>
              </div>
              <div class="control">
                <b-checkbox v-model="showLastHearts">
                  New Hearts
                </b-checkbox>
              </div>
              <div class="control">
                <b-checkbox v-model="showView">
                  Total views
                </b-checkbox>
              </div>
              <div class="control">
                <b-checkbox v-model="showLast24view">
                  New views
                </b-checkbox>
              </div>
              <div class="control">
                <b-checkbox v-model="showVideos">
                  Total Videos
                </b-checkbox>
              </div>
              <div class="control">
                <b-checkbox v-model="showLastVideo">
                  New Videos
                </b-checkbox>
              </div>
              <div class="control">
                <b-checkbox v-model="showFollowing">
                  Total Following
                </b-checkbox>
              </div>
              <div class="control">
                <b-checkbox v-model="showLastFollowing">
                  New following
                </b-checkbox>
              </div>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-table
              :data="orderedUser"
              :striped="true"
              :bordered="true"
              :mobile-cards="true"
              :paginated="true"
              per-page="40"
              :hoverable="true"
              pagination-position="bottom"
            >
              <template slot-scope="props">
                <b-table-column field="avatar" class="custom" width="70">
                  <figure class="image is-64x64">
                    <img class="is-rounded inset" :src="props.row.avatar" />
                  </figure>
                </b-table-column>
                <b-table-column field="nick_name" label="Nickname" sortable>
                  <router-link :to="getRouterLink(props.row.id)">
                    {{ props.row.nick_name }}
                  </router-link>
                </b-table-column>
                <b-table-column
                  field="fans"
                  label="Total fans"
                  sortable
                  numeric
                  :visible="showFans"
                >
                  {{ props.row.fans | formatNumber }}
                </b-table-column>
                <b-table-column
                  field="fans_gained"
                  label="New fans"
                  sortable
                  numeric
                  :visible="showLastFans"
                >
                  <span
                    :class="[
                      { 'has-text-danger': props.row.fans_gained <= 0 },
                      { 'has-text-success': props.row.fans_gained > 0 },
                    ]"
                    >{{ props.row.fans_gained | formatNumber }}</span
                  >
                </b-table-column>

                <b-table-column
                  field="heart"
                  label="Total Hearts"
                  sortable
                  numeric
                  :visible="showHearts"
                >
                  {{ props.row.heart | formatNumber }}
                </b-table-column>
                <b-table-column
                  field="heart_gained"
                  label="New hearts"
                  sortable
                  numeric
                  :visible="showLastHearts"
                >
                  <span
                    :class="[
                      { 'has-text-danger': props.row.heart_gained <= 0 },
                      { 'has-text-success': props.row.heart_gained > 0 },
                    ]"
                    >{{ props.row.heart_gained | formatNumber }}</span
                  >
                </b-table-column>
                <b-table-column
                  field="views"
                  label="Total Views"
                  sortable
                  numeric
                  :visible="showView"
                >
                  {{ props.row.views | formatNumber }}
                </b-table-column>
                <b-table-column
                  field="views_gained"
                  label="New Views"
                  sortable
                  numeric
                  :visible="showLast24view"
                >
                  <span
                    :class="[
                      { 'has-text-danger': props.row.views_gained <= 0 },
                      { 'has-text-success': props.row.views_gained > 0 },
                    ]"
                    >{{ props.row.views_gained | formatNumber }}</span
                  >
                </b-table-column>
                <b-table-column
                  field="following"
                  label="Total following"
                  sortable
                  numeric
                  :visible="showFollowing"
                >
                  {{ props.row.following | formatNumber }}
                </b-table-column>
                <b-table-column
                  field="following_gained"
                  label="New following"
                  sortable
                  numeric
                  :visible="showLastFollowing"
                >
                  <span
                    :class="[
                      { 'has-text-danger': props.row.following_gained <= 0 },
                      { 'has-text-success': props.row.following_gained > 0 },
                    ]"
                    >{{ props.row.following_gained | formatNumber }}</span
                  >
                </b-table-column>
                <b-table-column
                  field="video"
                  label="Total video"
                  sortable
                  numeric
                  :visible="showVideos"
                >
                  {{ props.row.video | formatNumber }}
                </b-table-column>
                <b-table-column
                  field="new_video"
                  label="New Video"
                  sortable
                  numeric
                  :visible="showLastVideo"
                >
                  <span
                    :class="[
                      { 'has-text-danger': props.row.new_video <= 0 },
                      { 'has-text-success': props.row.new_video > 0 },
                    ]"
                    >{{ props.row.new_video | formatNumber }}</span
                  >
                </b-table-column>
                <b-table-column field="tags" label="Tags">
                  <b-taglist v-if="props.row.tags">
                    <b-tag
                      v-for="t in props.row.tags"
                      :key="t"
                      rounded
                      closable
                      type="is-info"
                      @close="deleteTag(props.row.id, t)"
                    >
                      {{ t }}
                    </b-tag>
                  </b-taglist>
                </b-table-column>
                <b-table-column label="">
                  <b-button rounded size="is-small" @click="addTag(props.row.id)">
                    Add tag
                  </b-button>
                  <b-modal :active.sync="isTagModalActive" :width="320">
                    <div class="card">
                      <div class="card-content">
                        <div class="content">
                          <div class="columns is-mobile">
                            <div class="column">
                              <b-field label="Add a tag to your hashtag">
                                <b-input v-model="tag" />
                              </b-field>
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column has-text-right">
                              <b-button
                                class="has-margin-right-5"
                                @click="
                                  isTagModalActive = false;
                                  tag = '';
                                  currentTag = '';
                                "
                              >
                                Cancel
                              </b-button>
                              <b-button type="is-primary" @click="saveTag()">
                                Save
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-modal>
                </b-table-column>
                <b-table-column width="40">
                  <b-button
                    type="is-primary"
                    tag="router-link"
                    :to="getRouterLink(props.row.id)"
                    icon-right="finance"
                  />
                </b-table-column>
                <b-table-column width="40">
                  <a :href="getLink(props.row.unique_id)" target="_blank">
                    <b-button type="is-link" outlined icon-right="link" />
                  </a>
                </b-table-column>

                <b-table-column width="40">
                  <b-button
                    type="is-danger"
                    outlined
                    icon-right="delete"
                    @click="toDelete(props.row.id)"
                  />
                  <b-modal :active.sync="isCardModalActive" :width="320">
                    <div class="card">
                      <div class="card-content">
                        <div class="content">
                          <div class="columns is-mobile">
                            <div class="column">
                              Are you sure ?
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column has-text-right">
                              <b-button
                                class="has-margin-right-5"
                                @click="isCardModalActive = false"
                              >
                                Cancel
                              </b-button>
                              <b-button type="is-danger" @click="deleteSearch()">
                                Delete
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-modal>
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isCardModalActive: false,
      current: "",
      showLast24view: false,
      showView: true,
      showLastFollowing: false,
      showFollowing: false,
      showLastFans: true,
      showFans: true,
      showLastHearts: true,
      showHearts: true,
      showLastVideo: false,
      showVideos: true,
      isTagModalActive: false,
      currentTag: "",
      tag: "",
      tags: [],
      filteredTags: [],
    };
  },
  computed: {
    orderedUser() {
      const arr = this.tiktok.slice();
      const filtered = arr.filter((value, index, arr) => value.type === "user");
      filtered.sort((a, b) =>
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        a.nick_name.localeCompare(b.nick_name)
      );
      if (this.tags.length !== 0) {
        const r = [];
        this.tags.forEach(t => {
          filtered.forEach(element => {
            if (element.tags !== undefined) {
              element.tags.forEach(t => {
                if (this.tags.indexOf(t) !== -1) {
                  if (r.indexOf(element) === -1) {
                    r.push(element);
                  }
                }
              });
            }
          });
        });
        return r;
      }
      return filtered;
    },
    allTags() {
      const tags = [];
      const arr = this.tiktok.slice();
      const filtered = arr.filter((value, index, arr) => value.type === "user");
      filtered.forEach(element => {
        if (element.tags !== undefined) {
          element.tags.forEach(t => {
            if (tags.indexOf(t) === -1) {
              tags.push(t);
            }
          });
        }
      });
      return tags;
    },
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktok"]),
  },
  methods: {
    toDelete(t) {
      this.isCardModalActive = true;
      this.current = t;
    },
    getRouterLink(id) {
      return `/analytics/user/${id}`;
    },
    getLink(id) {
      return `https://www.tiktok.com/@${id}`;
    },
    deleteSearch() {
      this.$store.dispatch("tiktok/deleteSearch", { id: this.current });
      this.isCardModalActive = false;
      this.current = "";
    },
    addTag(id) {
      this.isTagModalActive = true;
      this.currentTag = id;
    },
    saveTag() {
      this.$store.dispatch("tiktok/saveMusicTag", {
        id: this.currentTag,
        tag: this.tag,
      });
      this.isTagModalActive = false;
      this.currentTag = "";
      this.$store.dispatch("tiktok/getTiktok");
    },
    deleteTag(id, tag) {
      this.$store.dispatch("tiktok/deleteMusicTag", { id: id, tag: tag });
      this.$store.dispatch("tiktok/getTiktok");
    },
    getFilteredTags(text) {
      this.filteredTags = this.allTags.filter(option => {
        return option.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    exportToCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      const row = "nick name,tags,fans,new fans,heart,total views,last 24h views,nb videos, url";
      csvContent += row + "\r\n";
      this.orderedUser.forEach(function(user) {
        if (user.views_gained === undefined) {
          user.views_gained = 0;
        }
        if (user.fans_gained === undefined) {
          user.fans_gained = 0;
        }
        if (user.views === undefined) {
          user.views = 0;
        }
        const tags = user.tags !== undefined ? user.tags.join(";") : "";
        csvContent +=
          user.nick_name +
          "," +
          tags +
          "," +
          user.fans +
          "," +
          user.fans_gained +
          "," +
          user.heart +
          "," +
          user.views +
          "," +
          user.views_gained +
          "," +
          user.video +
          "," +
          "https://www.tiktok.com/@" +
          user.unique_id +
          "\r\n";
      });
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },
};
</script>

<style>
table.table td .image {
  height: 64px;
  width: 64px;
}
.inset {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5);
}
</style>
